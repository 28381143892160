/* eslint-disable no-case-declarations */
import React, { useEffect } from "react";
import axios from "axios";
import LogRocket from "logrocket";
import {
  useHMSNotifications,
  HMSNotificationTypes,
  useHMSStore,
  selectRemotePeers,
  selectLocalPeer,
  selectPeers,
  selectLocalPeerRoleName,
  selectIsConnectedToRoom,
} from "@100mslive/react-sdk";
import { Flex, Text, Button } from "@100mslive/roomkit-react";
import { TrackUnmuteModal } from "./TrackUnmuteModal";
import { AutoplayBlockedModal } from "./AutoplayBlockedModal";
import { InitErrorModal } from "./InitErrorModal";
import { TrackBulkUnmuteModal } from "./TrackBulkUnmuteModal";
import { ToastManager } from "../Toast/ToastManager";
import { TrackNotifications } from "./TrackNotifications";
import { PeerNotifications } from "./PeerNotifications";
import { ReconnectNotifications } from "./ReconnectNotifications";
import { ToastBatcher } from "../Toast/ToastBatcher";
import { PermissionErrorModal } from "./PermissionErrorModal";
import { MessageNotifications } from "./MessageNotifications";
import { useLocation } from "react-router-dom";
import { Howl } from "howler";
import {
  useHLSViewerRole,
  useIsHeadless,
  useSubscribedNotifications,
} from "../AppData/useUISettings";
import { useNavigation } from "../hooks/useNavigation";
import { getMetadata } from "../../common/utils";
import { GetBreakoutRoomName } from "../RoleChangeModal";
import { toast } from "react-toastify";
import PeerJoinLeaveNotification from "./PeerJoinLeaveNotification";
import { useSetChatPeer, useChatPeer } from "../AppData/useChatPeer";
import { useSetPinnedPeer, usePinnerPeer } from "../AppData/usePinnedPeer";

export function Notifications() {
  const notification = useHMSNotifications();
  const remotePeers = useHMSStore(selectRemotePeers);
  const localPeerObj = useHMSStore(selectLocalPeer);
  const allPeers = useHMSStore(selectPeers);
  const navigate = useNavigation();
  const HLS_VIEWER_ROLE = useHLSViewerRole();
  const subscribedNotifications = useSubscribedNotifications() || {};
  const isHeadless = useIsHeadless();
  const localPeerRoleName = useHMSStore(selectLocalPeerRoleName);
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const pinnedPeer = usePinnerPeer();
  const chatPeerId = useChatPeer();
  const setPinnedPeer = useSetPinnedPeer();
  const setChatPeer = useSetChatPeer();
  let location = useLocation();
  let payload = {};
  const sound = new Howl({
    src: [
      `${process.env.REACT_APP_CLOUDFRONT_CDN_DOMAIN}/audios/answer-tone.mp3`,
      `${process.env.REACT_APP_CLOUDFRONT_CDN_DOMAIN}/audios/answer-tone.webm`,
    ],
    volume: 0.1,
  });
  // const userSound = new Howl({
  //   src: [
  //     `${process.env.REACT_APP_CLOUDFRONT_CDN_DOMAIN}/relax-message-tone.webm`,
  //     `${process.env.REACT_APP_CLOUDFRONT_CDN_DOMAIN}/relax-message-tone.mp3`,
  //   ],
  //   volume: 0.05,
  // });
  const roomName = GetBreakoutRoomName({
    roleName: notification?.data?.roleName,
    returnString: true,
  });
  useEffect(() => {
    if (!notification) {
      return;
    }

    switch (notification.type) {
      case HMSNotificationTypes.PEER_JOINED:
        if (allPeers.length) {
          const requestdata = {
            method: "post",
            url: `${process.env.REACT_APP_YOCKET_BACKEND}/lead/video-call/attendance`,
            headers: {
              "Content-Type": "application/json",
            },
            data: allPeers,
          };

          axios(requestdata)
            .then(function (response) {
              // console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        // if (localPeer === process.env.REACT_APP_WAITROOM_HOST_ROLE) {
        //   peerCount > 2 ? userSound.play() : sound.play();
        // }
        if (
          !notification.data?.isLocal &&
          notification.data?.roleName?.includes("guest") &&
          notification.data?.roleName?.includes("breakroom") &&
          localPeerRoleName ===
            notification.data?.roleName?.replace("guest", "host") &&
          window?.sessionStorage?.getItem("isVirtualOfficeSession") === "true"
        ) {
          sound.play();
        }
        if (
          isConnected &&
          !notification.data?.isLocal &&
          localPeerRoleName ===
            (notification.data?.roleName?.includes("guest")
              ? notification.data?.roleName?.replace("guest", "host")
              : notification.data?.roleName?.replace("host", "guest"))
        ) {
          toast(
            <PeerJoinLeaveNotification
              peerFirstName={notification.data?.name?.substring(0, 1)}
              text={`${notification.data?.name} has Joined`}
              isAdvisor={notification.data?.roleName?.includes("host")}
            />,
            {
              autoClose: 2000,
              type: toast.TYPE.INFO,
              closeButton: false,
              hideProgressBar: true,
              position: toast.POSITION.BOTTOM_LEFT,
              containerId: "PeerJoin",
              icon: false,
            }
          );
        }
        break;
      case HMSNotificationTypes.METADATA_UPDATED:
        // Don't toast message when metadata is updated and raiseHand is false.
        // Don't toast message in case of local peer and different roles.
        const metadata = getMetadata(notification.data?.metadata);
        if (!metadata?.isHandRaised || notification.data.isLocal || isHeadless)
          return;

        console.debug("Metadata updated", notification.data);
        if (!subscribedNotifications.METADATA_UPDATED) return;
        // prefix to -host and -guest
        let localPeerRoom = localPeerRoleName.split("-");
        localPeerRoom.pop();
        localPeerRoom = localPeerRoom.join("-");
        let userWhoPerformedActionRole = notification.data.roleName.split("-");
        userWhoPerformedActionRole.pop();
        userWhoPerformedActionRole = userWhoPerformedActionRole.join("-");
        if (
          !location.pathname.includes("preview") &&
          localPeerRoleName &&
          localPeerRoom === userWhoPerformedActionRole
        ) {
          ToastBatcher.showToast({ notification });
        }
        break;
      case HMSNotificationTypes.NAME_UPDATED:
        break;
      case HMSNotificationTypes.TRACK_MUTED:
        if (!notification.data.peerId) return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        payload = {
          type: "peer.muted.success",
          data: {
            peer_id: notification.data.peerId,
          },
        };
        // var config = {
        //   method: "post",
        //   url: `${process.env.REACT_APP_YOCKET_BACKEND}/manager/room-status-webhooks`,
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   data: payload,
        // };

        // axios(config)
        //   .then(function (response) {
        //     // console.log(JSON.stringify(response.data));
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        //   });
        break;
      case HMSNotificationTypes.TRACK_UNMUTED:
        if (!notification.data.peerId) return;
        payload = {
          type: "peer.unmuted.success",
          data: {
            peer_id: notification.data.peerId,
          },
        };
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_YOCKET_BACKEND}/manager/room-status-webhooks`,
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
        break;
      case HMSNotificationTypes.ERROR:
        if (
          notification.data?.isTerminal &&
          notification.data?.action !== "INIT"
        ) {
          if ([500, 6008].includes(notification.data?.code)) {
            ToastManager.addToast({
              title: `Error: ${notification.data?.message}`,
            });
          } else {
            LogRocket.track("Disconnected");
            // show button action when the error is terminal
            const toastId = ToastManager.addToast({
              title: (
                <Flex justify="between" css={{ w: "100%" }}>
                  <Text css={{ mr: "$4" }}>
                    {notification.data?.message ||
                      "We couldn’t reconnect you. When you’re back online, try joining the room."}
                  </Text>
                  <Button
                    variant="primary"
                    css={{ mr: "$4" }}
                    onClick={() => {
                      ToastManager.removeToast(toastId);
                      window.location.reload();
                    }}
                  >
                    Rejoin
                  </Button>
                </Flex>
              ),
              close: false,
            });
          }
          // goto leave for terminal if any action is not performed within 2secs
          // if network is still unavailable going to preview will throw an error
          setTimeout(() => {
            const previewLocation = window.location.pathname.replace(
              "meeting",
              "leave"
            );
            ToastManager.clearAllToast();
            navigate(previewLocation);
          }, 2000);
          return;
        }
        // Autoplay error or user denied screen share(cancelled browser pop-up)
        if (
          notification.data?.code === 3008 ||
          notification.data?.code === 3001 ||
          notification.data?.code === 3011
        ) {
          return;
        }
        if (notification.data?.action === "INIT") {
          return;
        }
        if (!subscribedNotifications.ERROR) return;
        ToastManager.addToast({
          title: `Error: ${notification.data?.message} - ${notification.data?.description}`,
        });
        break;
      case HMSNotificationTypes.ROLE_UPDATED:
        if (notification.data.roleName === HLS_VIEWER_ROLE) {
          return;
        }
        if (pinnedPeer && pinnedPeer === notification.data?.id) {
          setPinnedPeer(null);
        }
        if (chatPeerId && chatPeerId === notification.data?.id) {
          setChatPeer(null);
        }
        let localPeerChangedRoom = localPeerRoleName.split("-");
        localPeerChangedRoom.pop();
        localPeerChangedRoom = localPeerChangedRoom.join("-");
        let userWhoseRoleChanged = notification.data.roleName.split("-");
        userWhoseRoleChanged.pop();
        userWhoseRoleChanged = userWhoseRoleChanged.join("-");
        // console.log(
        //   "ROOM CHANGED AND HERES INFO: ",
        //   localPeerChangedRoom,
        //   userWhoseRoleChanged
        // );

        if (
          localPeerRoleName &&
          localPeerChangedRoom === userWhoseRoleChanged &&
          !localPeerRoleName.includes("waitroom")
        ) {
          if (localPeerRoleName.includes("host")) {
            let hostRole = localPeerRoleName.replace(/-guest|-host/g, "");

            let allRemotePeers = remotePeers.filter(item => {
              const remotePeerRole = item.roleName.replace(/-guest|-host/g, "");
              if (remotePeerRole === hostRole) {
                return item;
              }
            });
            const advisor = JSON.parse(localPeerObj?.metadata || "{}");
            const joinedUsers = notification.data?.roleName.includes("host")
              ? allRemotePeers.map(item => JSON.parse(item?.metadata || "{}"))
              : [JSON.parse(notification.data?.metadata || "{}")];

            if (advisor && joinedUsers.length) {
              const requestdata = {
                method: "post",
                url: `${process.env.REACT_APP_YOCKET_BACKEND}/lead/live-call/update-owner`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: {
                  users: joinedUsers,
                  advisor: advisor,
                },
              };

              axios(requestdata)
                .then(function (response) {
                  // console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
            // sound.play();
          } else {
            // userSound.play();
          }
        }

        // console.log("Notification Data: ", notification.data);
        // Ring to Host of that room
        if (
          notification.data?.isLocal &&
          window?.sessionStorage?.getItem("isVirtualOfficeSession") !== "true"
        ) {
          ToastManager.addToast({
            title: `You are now in ${roomName}`,
          });
        } else if (
          notification.data?.roleName?.includes("breakroom") &&
          notification.data?.roleName?.includes("guest") &&
          localPeerRoleName ===
            notification.data?.roleName?.replace("guest", "host") &&
          window?.sessionStorage?.getItem("isVirtualOfficeSession") === "true"
        ) {
          sound.play();
        }
        if (
          isConnected &&
          !notification.data?.isLocal &&
          localPeerRoleName ===
            (notification.data?.roleName?.includes("guest")
              ? notification.data?.roleName?.replace("guest", "host")
              : notification.data?.roleName?.replace("host", "guest"))
        ) {
          toast(
            <PeerJoinLeaveNotification
              peerFirstName={notification.data?.name?.substring(0, 1)}
              text={`${notification.data?.name} has Joined`}
              isAdvisor={notification.data?.roleName?.includes("host")}
            />,
            {
              autoClose: 2000,
              type: toast.TYPE.INFO,
              closeButton: false,
              hideProgressBar: true,
              position: toast.POSITION.BOTTOM_LEFT,
              containerId: "PeerJoin",
              icon: false,
            }
          );
        }
        break;
      case HMSNotificationTypes.CHANGE_TRACK_STATE_REQUEST:
        const track = notification.data?.track;
        if (!notification.data.enabled) {
          ToastManager.addToast({
            title: `Your ${track.source} ${track.type} was muted by
                ${notification.data.requestedBy?.name}.`,
          });
        }
        break;
      case HMSNotificationTypes.ROOM_ENDED:
      case HMSNotificationTypes.REMOVED_FROM_ROOM:
        ToastManager.addToast({
          title: `${notification.message}. 
              ${
                notification.data.reason &&
                `Reason: ${notification.data.reason}`
              }`,
        });
        setTimeout(() => {
          const leaveLocation = window.location.pathname.replace(
            "meeting",
            "leave"
          );
          navigate(leaveLocation);
        }, 2000);
        break;
      case HMSNotificationTypes.DEVICE_CHANGE_UPDATE:
        ToastManager.addToast({
          title: notification.message,
        });
        break;
      case HMSNotificationTypes.PEER_LEFT:
        if (pinnedPeer && pinnedPeer.id === notification.data?.id) {
          setPinnedPeer(null);
        }
        if (chatPeerId && chatPeerId === notification.data?.id) {
          setChatPeer(null);
        }
        if (
          localPeerRoleName === process.env.REACT_APP_WAITROOM_HOST_ROLE &&
          !notification.data.roleName.includes("waitroom")
        ) {
          toast(`${notification.data.name} left ${roomName}`, {
            autoClose: 2000,
            type: toast.TYPE.INFO,
            closeButton: false,
            hideProgressBar: true,
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "PeerLeft",
            icon: false,
          });
        }

        if (
          isConnected &&
          !notification.data?.isLocal &&
          localPeerRoleName ===
            (notification.data?.roleName?.includes("guest")
              ? notification.data?.roleName?.replace("guest", "host")
              : notification.data?.roleName?.replace("host", "guest"))
        ) {
          toast(
            <PeerJoinLeaveNotification
              peerFirstName={notification.data?.name?.substring(0, 1)}
              text={`${notification.data?.name} has left the meeting`}
              isAdvisor={notification.data?.roleName?.includes("host")}
            />,
            {
              autoClose: 2000,
              type: toast.TYPE.INFO,
              closeButton: false,
              hideProgressBar: true,
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "PeerLeft",
              icon: false,
            }
          );
        }
        break;
      default:
        break;
    }
  }, [
    notification,
    subscribedNotifications.ERROR,
    subscribedNotifications.PEER_JOINED,
    subscribedNotifications.METADATA_UPDATED,
    HLS_VIEWER_ROLE,
  ]);

  return (
    <>
      {!isHeadless && <TrackUnmuteModal />}
      {!isHeadless && <TrackBulkUnmuteModal />}
      <TrackNotifications />
      <PeerNotifications />
      <ReconnectNotifications />
      <AutoplayBlockedModal />
      <PermissionErrorModal />
      <MessageNotifications />
      <InitErrorModal notification={notification} />
    </>
  );
}
